import "./App.css";
import { Leva } from "leva";
import { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ProjectCard from "./Components/blocks/ProjectCard";

const ENV_MODE = import.meta.env.MODE || "production";
const isProd = ENV_MODE === "production";

function FallBack({ error, resetErrorBoundary }) {
  return (
    <div>
      <p>Somthing went wrong</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const NAVIGATION_CARDS = [
  {
    title: "Welcome",
    image: "/projects/welcome-screenshot.png",
    desc: "",
    link: "/",
    hide: true,
  },
  {
    title: "lLofi donuts",
    image: "/projects/lofi-donuts-screenshot.png",
    desc: "",
    link: "donuts",
  },
  {
    title: "Audio Galaxy",
    image: "/projects/audio-galaxy-screenshot.png",
    desc: "",
    link: "galaxy",
  },
  {
    title: "Moon fox",
    image: "/projects/moon-fox-screenshot.png",
    desc: "",
    link: "target-morph",
  },

  {
    title: "Anime objects",
    image: "/projects/anime-objects.png",
    desc: "",
    link: "animatedObjects",
    hide: true,
  },
];

function App() {
  const [hash, setHash] = useState("");

  const getHash = () =>
    typeof window !== "undefined"
      ? decodeURIComponent(window.location.hash.replace("#", ""))
      : undefined;

  useEffect(() => {
    const handleHashChange = () => {
      setHash(getHash());
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // TODO: Lazy load with an experience selector

  return (
    <div className="relative overflow-hidden flex h-full">
      <div className="absolute z-[2000] left-0 top-0 h-full w-fit">
        <div className="h-full flex items-center">
          <div className="relative w-[200px] navigator h-fit flex flex-col overflow-y-scroll p-2 ml-2 rounded-md bg-[navajowhite]">
            {NAVIGATION_CARDS.map((props) => {
              if (props.hide) return null;
              else return <ProjectCard key={props.title} {...props} />;
            })}
          </div>
        </div>
      </div>

      <div className="wrapper content h-full">
        <Leva
          // hidden={isProd&& hash !== "debug"}
          collapsed={isProd}
        />
        <ErrorBoundary fallbackRender={FallBack}>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
        {/* <AudioGraph /> */}
        {/* <ToolBar /> */}
      </div>
    </div>
  );
}

export default App;
