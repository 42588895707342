import { useLocation, useNavigate } from "react-router-dom";

function ProjectCard({ title, image, desc, link }) {
  const navigate = useNavigate();
  const location = useLocation();
  const selected = `/${link}` === location.pathname;

  const styles = selected
    ? {
        background: `radial-gradient(circle, transparent 40%, var(--color-v) 75%),linear-gradient(to right, var(--color), var(--color)), url("${image}")`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundBlendMode:
          "var(--blend-top, normal),var(--blend-bottom, saturation),normal",
      }
    : { background: `center / cover no-repeat url("${image}")` };

  return (
    <div
      key={title}
      className="card w-full
      my-1
       flex flex-col justify-around cursor-pointer"
      onClick={() => navigate(link)}
      style={{ border: "1px solid black" }}
    >
      <div className="title">{/* <h2 className="text-3xl">{title}</h2> */}</div>
      <div
        className={`screenshot h-36 flex items-end justify-center`}
        style={styles}
      >
        <p
          className="bg-[#181C20] p-1 m-2 opacity-80"
          style={{ backdropFilter: "contrast(20px)" }}
        >
          {title}
        </p>
      </div>
      <div className="optional-description">
        {/* {desc} */}
        <h2 className="text-2xl"></h2>
      </div>
    </div>
  );
}

export default ProjectCard;
