import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { createBrowserRouter, Route } from "react-router-dom";
import { RouterProvider } from "react-router-dom";

// import GalaxyParticlesExperience from './Components/Experiences/GalaxyParticles/index.jsx'
// import Shaders from './Components/Experiences/Shaders/index.jsx'
import { lazy } from "react";
import WelcomePage from "./Components/WelcomePage/index.jsx";

const AnimatedObjects = lazy(() =>
  import("./Components/Sandboxes/AnimatedObjects/index.jsx")
);
const Shaders = lazy(() => import("./Components/Sandboxes/Shaders/index.jsx"));
const Galaxy = lazy(() =>
  import("./Components/Sandboxes/GalaxyParticles/index.jsx")
);
const SurfaceMap = lazy(() =>
  import("./Components/Sandboxes/SurfaceMap/index.jsx")
);
const TargetMorph = lazy(() =>
  import("./Components/Sandboxes/TargetMorph/index.jsx")
);

const Charting = lazy(() =>
  import("./Components/Sandboxes/Charting/index.jsx")
);

const Donuts = lazy(() =>
  import("./Components/Sandboxes/Donuts/index.jsx")
);

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {path: '/', element: <WelcomePage /> },
      {
        path: "galaxy",
        element: <Galaxy />,
      },
      {
        path: "animatedObjects",
        element: <AnimatedObjects />,
      },
      {
        path: "donuts",
        element: <Donuts />,
      },
      {
        path: "shaders",
        element: <Shaders />,
      },
      {
        path: "surface-map",
        element: <SurfaceMap />,
      },
      {
        path: "target-morph",
        element: <TargetMorph />,
      },
      {
        path: "charting",
        element: <Charting />,
        // index: true,
      },
    ],
  },
]);



ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);
