import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import gsap from "gsap";
import { ScreenCut } from "../UIAnimation/Screen";
import { Html, Stars } from "@react-three/drei";
import { TextPlugin } from "gsap/all";

gsap.registerPlugin(TextPlugin);

// Text storke anim: https://codepen.io/pavanas/pen/dPLQPX

const HtmlContent = () => {
  const container = useRef(null);

  const animateContentIn = (containerWidth) => {
    const startPosition = -(containerWidth + 200);
    const stopPosition = 200;
    gsap.fromTo(".welcome", { x: startPosition }, { x: stopPosition });
  };

  const animateFirstLine = () => {
    const timePerCharacter = 0.2;

    words.forEach((el) => {
      tl.from(el, {
        text: "",
        duration: el.innerHTML.length * timePerCharacter,
        ease: "none",
      });
    });
  };

  useGSAP(
    () => {
      const { width: containerWidth } =
        container.current.getBoundingClientRect();

      // animateContentIn(containerWidth);

      let tl = gsap.timeline({ delay: 1.5 });
      let words = gsap.utils.toArray("svg text");

      let toText = ["", "go", "play", "Around:)"];

      const timePerCharacter = 0.15;

      words.forEach((el, i) => {
        if (i === 2) {
          tl.from(el, {
            duration: toText[i] ? 1 : 0,
            rotate: -90,
          });
        }

        tl.from(el, {
          text: "",
          duration: el.innerHTML.length * timePerCharacter,
          ease: "none",
        });
      });

      words.forEach((el, i) => {
        if (i === 2) {
          tl.to(el, {
            duration: 0,
            text: toText[i],
            ease: "none",
          });

          // move
          // tl.to(el, {
          //   duration: toText[i] ? 1 : 0,
          //   rotate: "+=90",
          //   ease: "circ.in",
          //   yoyoEase: true,
          // });

          tl.to(el, {
            duration: 0.5,
            y: "+=20",
            // fontSize: "+=5",
            ease: "expo.out",
            yoyoEase: true,
          });

        } else {
          tl.to(el, {
            duration: toText[i] ? 1 : 0,
            text: toText[i],
            ease: "none",
          });
        }
      });

      tl.to(".svgText", { x: containerWidth + 200, duration: 1, delay: 1 });
    },
    { scope: container }
  );

  return (
    <div
      ref={container}
      style={{ width: "100dvw", height: "100%", position: "relative" }}
    >
      <ScreenCut duration={0.5} />

      <div className="w-full h-full bg-transparent flex justify-center items-center">
        <div className="relative svgText w-full fill-white">
          <svg viewBox="0 0 240 80" xmlns="http://www.w3.org/2000/svg">
            <text
              x="30"
              y="35"
              className="small"
              style={{ font: "italic 10px sans-serif" }}
            >
              Welcome
            </text>
            <text
              x="40"
              y="35"
              className="heavy fill-black"
              style={{ font: "bold 25px sans-serif" }}
            >
              to
            </text>
            <text
              x="55"
              y="55"
              className="small"
              style={{ font: "italic 10px sans-serif" }}
            >
              my
            </text>
            <text
              x="40"
              y="55"
              className="Rrrrr"
              style={{ font: "italic 30px serif", fill: "red" }}
            >
              Sandbox!
            </text>
          </svg>
        </div>
      </div>
    </div>
  );
};

function Content() {
  return (
    <>
      <Stars count={100} radius={1} speed={2} />
      <ambientLight color="white" />
      <Html fullscreen zIndexRange={[1000]}>
        <HtmlContent />
      </Html>
    </>
  );
}

export default Content;
